div > .Mui-expanded {
  background-color: #45507f;
  color: #fff;
}
div.MuiAccordionDetails-root {
  background-color: #fff;
  color: #000;
}
div.MuiButtonBase-root.MuiAccordionSummary-root.Mui-expanded {
  height: 48px !important;
  min-height: 48px !important;
}
