.headerlogoright{
    display: inline-block;
    float: right;
}
.headerlogoright img{
    height: 40px;
}
.headerlogoleft img{
    height: 40px;
}
.headerlogoleft{
    display: inline-block;
}
.description{
    text-align: center;
    padding: 10px;
    border-top: 2px solid #000;
}
.hbname{
    font-size: 20px;
    font-weight: 600;
}
.footer{
    border-top: 2px solid #000;
    padding-top: 10px;
}

.disclaimer{
    font-size: 12px;
}
.disclaimer img{
   max-width: 100%;
   left: 0;
   margin-right: 10px;
}
.social-list{
    margin-right: 5px;
    display: inline-block;
    background: #475280;
    text-align: center;
    width: 32px;
    padding: 5px;
    border-radius: 56px;
}
ul.social-media li{
    display: inline;
}
.social-media{
    padding: 0;
    margin: 0;
}
.print-container{
    width: 750px;
}
.contain-wrapper{
    min-height: 108vh;
}
.floorplan-img{
    max-height: 650px;
}